import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  foodGallery2,
  mobileGallery,
  mobileGallery2,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-55px">
          <CFImage src={mobileGallery} w="100%" alt="Mobile Gallery" />
          <CFImage
            src={mobileGallery2}
            w="97.5%"
            alt="Mobile Gallery"
            mb="15px"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="40px auto">
          <CFImage src={foodGallery} w="90%" alt="Food Gallery" mb="35px" />
          <CFImage src={foodGallery2} w="90%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
